import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import sprite from '../media/icons.svg';
import './NoteContent.css';
function NoteContent(props) {
  const [items, setItems] = useState([]);
  function handlerPosts(id){
      fetch(`https://admincode.code4u.com.ua/wp-json/wp/v2/news/${id}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setItems(data);
        });
  }
  const test = items.length === 0 ? props.item : {items};
  return (
    <section className="note-content">
      {Object.values(test).map((item, index) => {
        console.log(item);
        const prev = item.previous && `/blog/${item.previous.slug}`;
        const next = item.next && `/blog/${item.next.slug}`;
        return <div className="container" key={index}>
          <div className="note-content__date">13 June, 2021</div>
          <h1 className="note-content__headline">{item.title.rendered}</h1>
          <img className="img-block" src={item.images.full} alt="" />
          <div dangerouslySetInnerHTML={{
            __html: item.content.rendered
          }}></div>
          <div className="note-nav">
            <div className="note-nav__item note-nav__item_prev">
              {prev && <>
              <h3 className="note-nav__headline">{item.previous.title}</h3>
              <Link className="btn-main" to={prev} onClick={() => handlerPosts(item.previous.id)}>
                <svg className="btn-main__icon">
                  <use href={sprite + "#arrow-prev"}></use>
                </svg>НАЗАД
              </Link>
              </>
              }
            </div>
            <div className="note-nav__item note-nav__item_next">
            {next && <>
              <h3 className="note-nav__headline">{item.next.title}</h3>
              <Link className="btn-main" to={next} onClick={() => handlerPosts(item.next.id)}>ВПЕРЕД
                <svg className="btn-main__icon">
                  <use href={sprite + "#arrow"}></use>
                </svg>
              </Link>
              </>
              }
            </div>
          </div>
        </div>
      })}
    </section>
  );
}
export default NoteContent;
