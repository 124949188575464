import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AboutBanner from './components/AboutBanner';
import AboutContent from './components/AboutContent';
import AboutWhatWeDo from './components/AboutWhatWeDo';
import AboutAchievements from './components/AboutAchievements';
import StartProject from './components/StartProject';
function About(props) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = "CODE4U | Про нас";
  }, []);
  if(location.pathname !== '/'){
    document.body.classList.remove('main-page');
  }
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  useEffect(() => {
    fetch('https://admincode.code4u.com.ua/wp-json/wp/v2/pages?slug=about')
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
      });
  }, [])
  function elementInViewport(el, num){
      var bounds = el.getBoundingClientRect();
      return (
          ((bounds.top - num) + (bounds.height) > 0) && // Елемент ниже верхней границы
          ((window.innerHeight - num) - (bounds.top) > 0) && // Выше нижней
          ((bounds.left  - num) + (bounds.width) > 0) && // Правее левой
          ((window.innerWidth  - num) - (bounds.left) > 0)// Левее правой
      );
  }
  function handlerScroll(){
        var el3 = document.querySelector(".start-project");
          var inViewport3 = elementInViewport(el3, 150);
          if(inViewport3){
            el3.classList.add('active');
          }
  }
  return (
    <div onWheel={handlerScroll}>
      <AboutBanner items={items} />
      <AboutContent items={items} />
      <AboutWhatWeDo items={items} />
      <AboutAchievements items={items} icons={props.icons} />
      <StartProject />
    </div>
  );
}
export default About;
