import React, { useState, useEffect } from "react";
import { spring, AnimatedSwitch } from 'react-router-transition';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Links
} from "react-router-dom";
import name_c from './media/name-c.svg';
import name_o from './media/name-o.svg';
import name_d from './media/name-d.svg';
import name_e from './media/name-e.svg';
import name_4 from './media/name-4.svg';
import name_u from './media/name-u.svg';
import name_l_c from './media/name-l-c.svg';
import name_l_o from './media/name-l-o.svg';
import name_l_d from './media/name-l-d.svg';
import name_l_e from './media/name-l-e.svg';
import name_l_4 from './media/name-l-4.svg';
import name_l_u from './media/name-l-u.svg';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './Home';
import Blog from './Blog';
import Portfolio from './Portfolio';
import About from './About';
import Contacts from './Contacts';
import SingleNote from './SingleNote';
import SinglePortfolio from './SinglePortfolio';
import NotFound from './NotFound';
const routes = [
  { path: '/blog/:slug', name: 'SingleNote', Component: SingleNote },
  { path: '/blog', name: 'Blog', Component: Blog },
  { path: '/cases/:slug', name: 'SinglePortfolio', Component: SinglePortfolio },
  { path: '/cases', name: 'Portfolio', Component: Portfolio },
  { path: '/about', name: 'About', Component: About },
  { path: '/contacts', name: 'Contacts', Component: Contacts },
  { path: '/', name: 'Home', Component: Home },
];
function App() {
  const [theme, setTheme] = useState(false);
  const [content, setContent] = useState(false);
  setTimeout(() => {
    setContent(true);
  }, 3000);
  function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
  };
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 50,
  });
}

// child matches will...
const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 1.05,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(1.05),
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};
function handlerTheme(){
  setTheme(!theme);
}
const logoItems = theme ? [name_l_c, name_l_o, name_l_d, name_l_e, name_l_4, name_l_u] : [name_c, name_o, name_d, name_e, name_4, name_u];
const logoItemsResult = logoItems.map((item, index) => {
  return <img className="logo-item" src={item} alt="" key={index} />;
});
  return (
    <>
    <Router>
      <div className={theme ? 'wrapper wrapper__light' : 'wrapper'}>
      {!content && <div className="preloader">
      <div className="preloader-box">
    		<div>C</div>
    		<div>O</div>
    		<div>D</div>
    		<div>E</div>
    		<div>4</div>
    		<div>U</div>
    	</div>
      </div>}
      <Header theme={theme} />
        <main className="main">
        <div className="switch-theme">
        <div className="container switch-theme__container">
          <div className="tg-list-item">
           <input className="tgl tgl-flat" id="cb4" type="checkbox" onChange={handlerTheme}/><label className="tgl-btn" htmlFor="cb4"></label>
          </div>
          </div>
        </div>
          <AnimatedSwitch
            atEnter={bounceTransition.atEnter}
            atLeave={bounceTransition.atLeave}
            atActive={bounceTransition.atActive}
            mapStyles={mapStyles}
              className="animated"
          >
          {routes.map(({ path, Component }) => (
            <Route key={path} exact path={path}>
              {path === '/' && <Component logoResult={logoItemsResult} />}
              {path === '/about' && <Component icons={theme} />}
              {(path !== '/about' && path !== '/') && <Component />}
            </Route>
          ))}
          <Route path="*" component={NotFound} />
          </AnimatedSwitch>
        </main>
        <Footer theme={theme} />
      </div>
      </Router>
    </>
  );
}
export default App;
