import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './Menu.css';
import { useLocation } from "react-router-dom";
function Menu(props) {
  let [showBlur, setShowBlur] = useState(false);
  let [showMenuInner, setShowMenuInner] = useState(false);
    useEffect(() => {
      const timer = setTimeout(() => {
        setShowBlur(true);
      }, 1);
      return () => clearTimeout(timer);
    }, []);
    useEffect(() => {
      const timer2 = setTimeout(() => {
        setShowMenuInner(true);
      }, 200);
      return () => clearTimeout(timer2);
    }, []);
  function handlerCloseMenu(){
    setShowBlur(false);
    setTimeout(setShowMenuInner(!showMenuInner), 300);
    setTimeout(props.showFun, 500);
  }
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <div className="menu">
      <div className={showBlur ? 'menu__overlay active' : 'menu__overlay'} onClick={handlerCloseMenu}></div>
      <div className={showMenuInner ? 'menu__inner active' : 'menu__inner'}>
        <ul className="menu-list">
          <li>
            <Link to="/" onClick={handlerCloseMenu} className={splitLocation[1] === "" ? "active" : ""}>Головна сторінка</Link>
          </li>
          <li>
            <Link to="/cases" onClick={handlerCloseMenu} className={splitLocation[1] === "cases" ? "active" : ""}>Портфоліо</Link>
          </li>
          <li>
            <Link to="/about" onClick={handlerCloseMenu} className={splitLocation[1] === "about" ? "active" : ""}>Про нас</Link>
          </li>
          <li>
            <Link to="/contacts" onClick={handlerCloseMenu} className={splitLocation[1] === "contacts" ? "active" : ""}>Контакти</Link>
          </li>
          <li>
            <Link to="/blog" onClick={handlerCloseMenu} className={splitLocation[1] === "blog" ? "active" : ""}>Блог</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default Menu;
