import React from "react";
import {
  Link
} from "react-router-dom";
import sprite from '../media/icons.svg';
import './BlogHeader.css';
function BlogHeader() {
  return (
    <>
      <div className="container">
        <ul className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link className="breadcrumbs__link" to="/">Главная</Link>
          </li>
          <li className="breadcrumbs__item">Блог</li>
        </ul>
      </div>
      <section className="blog-header">
        <div className="container">
          <h2 className="note-headline">Блог</h2>
          <div className="note-category">Категорії</div>
        </div>
      </section>
    </>
  );
}
export default BlogHeader;
