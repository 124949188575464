import React, { useState, useEffect } from "react";
import Masonry from 'react-masonry-css'
import './HomeProducts.css';
function HomeProducts() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  function elementInViewport(el){
      var bounds = el.getBoundingClientRect();
      return (
          ((bounds.top - 300) + (bounds.height) > 0) && // Елемент ниже верхней границы
          ((window.innerHeight - 300) - (bounds.top) > 0) && // Выше нижней
          ((bounds.left  - 300) + (bounds.width) > 0) && // Правее левой
          ((window.innerWidth  - 300) - (bounds.left) > 0)// Левее правой
      );
  }


  document.addEventListener("scroll", (e) => {
      var el = document.querySelectorAll(".product-wgt");
      for(let i = 0; i < el.length; i++){
        var inViewport = elementInViewport(el[i]);
        if(inViewport){
          el[i].classList.add('active');
        }
      }
  })

  useEffect(() => {
    fetch('https://admincode.code4u.com.ua/wp-json/acf/v3/options/acf-products')
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data.acf.home_products);
      });
  }, [])
  return (
    <section className="home-products">
      {Object.values(items).map((item, index) => {
        let obj = Object.assign({}, item.products_list);
        let num = index + 1;
        let product = Object.values(obj).map((product, i) => {
          return                     <li className="product-list__item" key={i}>
                                <span className="product-list__value">{product.name}</span>
                              </li>
        });
        return <div className="product-wgt" key={index}>
          <div className="container">
            <div className="product-wgt__inner">
              <div className="product-wgt__info">
                <div className="product-wgt__header">
                  <div className="product-wgt__num">{num < 10 ? '0' + num : num}</div>
                  <div className="product-wgt__name">{item.name}</div>
                </div>
                <div className="product-wgt__content">
                  <ul className="product-list">
                    {product}
                  </ul>
                </div>
              </div>
              <div className="product-wgt__gallery">
                <Masonry breakpointCols={2} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                  <div className="product-animation product-animation_1">
                    <div className="product-animation__line"></div>
                    <img className="img-block" src={item.image_2} alt=""/>
                  </div>
                  <div className="product-animation product-animation_2">
                    <div className="product-animation__line"></div>
                    <img className="img-block" src={item.image_1} alt=""/>
                  </div>
                  <div className="product-animation product-animation_3">
                    <div className="product-animation__line"></div>
                    <img className="img-block" src={item.image_3} alt=""/>
                  </div>
                </Masonry>
              </div>
            </div>
          </div>
        </div>
      })}
    </section>
  );
}
export default HomeProducts;
