import React, { useState, useEffect } from "react";
import './FeedBack.css';
import sprite from '../media/icons.svg';
function FeedBack() {
  const [form, setForm] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);
  function handlerSetForm (event) {
    setForm(true);
  }
  function handlerCloseForm(){
    setForm(false);
  }
  function handlePhone (event) {
    setPhone(event.target.value);
  }
  function handleName (event) {
    setName(event.target.value);
  }
  function handlerSubmitForm (event) {
    event.preventDefault();
    fetch("https://code4u.com.ua/form-getcontact.php",
      {   method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          mode : 'same-origin',
          credentials: 'same-origin' ,
          body: JSON.stringify({
            name: name,
            phone: phone,
          })
      })
      .then(function(response) {
        return response.text()
      }).then(function(text) {
        let result = JSON.parse(text);
        if(result == 'success'){
          setTimeout(() => {
            setSubmitSuccess(true);
          }, 2000);
        } else {

        }
      });
  }
  return (
    <>
      {form && <><div className="feedback-form" onClick={handlerCloseForm}></div>
        <form onSubmit={handlerSubmitForm}>
          <div className="question-form-wrapper__header">
            <button className="question-form__close" type="button" onClick={handlerCloseForm}>
              <svg className="question-form__close-icon">
                <use href={sprite + "#close-icon"}></use>
              </svg>
            </button>
          </div>
          <div className="question-form-wrapper__header">{submitSuccess ? 'Дякуємо наш менеджер зв\'яжеться з Вами!' : 'Залиште свій контакт'}</div>
          {!submitSuccess && <><div className="question-form-wrapper__field">
            <input placeholder="Як до Вас звертатися?" value={name} onChange={handleName} />
          </div>
          <div className="question-form-wrapper__field">
            <input placeholder="Номер телефону" value={phone} onChange={handlePhone} />
          </div>
          <div className="question-form-wrapper__footer">
            <button className="question-form-wrapper__btn btn-main" type="submit">Відправити
              <svg className="btn-main__icon">
                <use href={sprite + "#arrow"}></use>
              </svg>
            </button>
          </div></>}
        </form>
      </>}
      <button className="question-form__btn" type="button" onClick={() => handlerSetForm()}>
        <svg className="question-form__icon">
          <use href={sprite + "#question-icon"}></use>
        </svg>
      </button>
    </>
  );
}
export default FeedBack;
