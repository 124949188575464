import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import FeedBack from './FeedBack';
import sprite from '../media/icons.svg';
import './Footer.css';
function Footer(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [contacts, setContacts] = useState([]);
  useEffect(() => {
      fetch("https://admincode.code4u.com.ua/wp-json/acf/v3/options/acf-contacts")
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setContacts(result);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
  }, []);
  const contactsObj = Object.assign({}, contacts.acf);
  return (
    <>
    <FeedBack />
    <footer className="footer">
      <div className="footer__top">
        <div className="container">
          <div className="footer__row container__row">
            <div className="footer__top_left">
              <a href="#">
                <img className="footer__logo" src={props.theme ? 'http://admincode.code4u.com.ua/wp-content/uploads/2021/11/light_logo.svg' : 'http://admincode.code4u.com.ua/wp-content/uploads/2021/11/logo.svg'} alt="" />
              </a>
              <p className="footer__description">Створюємо маркетингові кампанії та IT продукти на будь-який смак.</p>
              <ul className="footer-contacts">
                <li className="footer-contacts__item">
                  <svg className="footer-contacts__icon">
                    <use href={sprite + "#phone-icon"}></use>
                  </svg>
                  <a className="footer-contacts__link" href={"tel:" + contactsObj.phone}>{contactsObj.phone}</a>
                </li>
                <li className="footer-contacts__item">
                  <svg className="footer-contacts__icon">
                    <use href={sprite + "#mail-icon"}></use>
                  </svg>
                  <a className="footer-contacts__link" href={"mailto:" + contactsObj.email}>{contactsObj.email}</a>
                </li>
              </ul>
            </div>
            <div className="footer__top_right">
              <div className="footer-nav">
                <h3 className="footer-nav__headline">Навігація</h3>
                <ul>
                  <li>
                    <Link to="/">Головна сторінка</Link>
                  </li>
                  <li>
                    <Link to="/about">Про нас</Link>
                  </li>
                  <li>
                    <Link to="/blog">Блог</Link>
                  </li>
                </ul>
              </div>
              <div className="footer-nav">
                <h3 className="footer-nav__headline">Портфоліо</h3>
                <ul>
                  <li>
                    <Link to="/cases">Наші проєкти</Link>
                  </li>
                </ul>
              </div>
              <div className="footer-nav">
                <h3 className="footer-nav__headline">Зв'язок</h3>
                <ul>
                  <li>
                    <Link to="/contacts">Контакти</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="container">
          <div className="footer__row container__row">
            <div className="footer__bottom_left">
              <p className="footer__copyright">© 2022 Всі права захищені</p>
            </div>
            <div className="footer__bottom_right">
              <ul className="footer-social">
                <li>
                  <a href={contactsObj.instagram} target="_blank">Instagram</a>
                </li>
                <li>
                  <a href={contactsObj.facebook} target="_blank">Facebook</a>
                </li>
                <li>
                  <a href={contactsObj.telegram} target="_blank">Telegram</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
    </>
  );
}
export default Footer;
