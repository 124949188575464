import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useLocation
} from "react-router-dom";
import PortfolioBanner from './components/PortfolioBanner';
import PortfolioInfo from './components/PortfolioInfo';
import PortfolioContent from './components/PortfolioContent';
import StartProject from './components/StartProject';
function SinglePortfolio() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  if(location.pathname !== '/'){
    document.body.classList.remove('main-page');
  }
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [title, setTitle] = useState(false);
  let { slug } = useParams();
  useEffect(() => {
    fetch(`https://admincode.code4u.com.ua/wp-json/wp/v2/cases?slug=${slug}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
      });
  }, [])
  Object.values(items).map((item, index) => {
    if(!title){
      setTitle(item.title.rendered);
    }
  });
  useEffect(() => {
    if(title){
      document.title = 'CODE4U | ' + title;
    }
  }, [title]);
  function elementInViewport(el, num){
      var bounds = el.getBoundingClientRect();
      return (
          ((bounds.top - num) + (bounds.height) > 0) && // Елемент ниже верхней границы
          ((window.innerHeight - num) - (bounds.top) > 0) && // Выше нижней
          ((bounds.left  - num) + (bounds.width) > 0) && // Правее левой
          ((window.innerWidth  - num) - (bounds.left) > 0)// Левее правой
      );
  }
  function handlerScroll(){
        var el3 = document.querySelector(".start-project");
          var inViewport3 = elementInViewport(el3, 50);
          if(inViewport3){
            el3.classList.add('active');
          }
  }
  return (
    <div onWheel={handlerScroll}>
      <PortfolioBanner item={items} />
      <PortfolioInfo item={items} />
      <PortfolioContent item={items} />
      <StartProject item={items} />
    </div>
  );
}
export default SinglePortfolio;
