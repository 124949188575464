import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import sprite from '../media/icons.svg';
import './BlogList.css';
function BlogList() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [offset, setOffset] = useState(12);
  const [count, setCount] = useState([]);
  const pointItems = ['note-wgt__headline_theme_1', 'note-wgt__headline_theme_2', 'note-wgt__headline_theme_3'];
  useEffect(() => {
    fetch(`https://admincode.code4u.com.ua/wp-json/wp/v2/news?per_page=12`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
      });
      fetch(`https://admincode.code4u.com.ua/wp-json/wp/v2/news?per_page=100`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setCount(data.length);
        });
  }, [])
  function handlerPrev(){
      fetch(`https://admincode.code4u.com.ua/wp-json/wp/v2/news?per_page=12&offset=${offset - 24}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setItems(data);
        });
        setOffset(offset - 12);
  }
  function handlerNext(){
    fetch(`https://admincode.code4u.com.ua/wp-json/wp/v2/news?per_page=12&offset=${offset}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
        setOffset(offset + 12);
      });
  }
  return (
    <section className="blog-list">
      <div className="container">
        <div className="notes">
            {Object.values(items).map((item, index) => {
              const date = new Date(item.date);
              const month = date.getMonth() + 1;
              const day = date.getDate();
              let headlineClass = 'note-wgt__headline ' + pointItems[index];
              return <div className="note-wgt" key={index}>
                <div className="note-wgt__inner">
                  <div className="note-wgt__date">{date.getFullYear() + '/' + (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day)}</div>
                    <div className="note-wgt__content">
                      <Link className="note-wgt__link" to={`/blog/${item.slug}`} params={{slug: item.slug}}>
                        <div className={headlineClass} dangerouslySetInnerHTML={{
                          __html: item.excerpt.rendered.substr(0, 160) + '...'
                        }}></div>
                        <div className="note-wgt__thumbnail-wrap">
                          <img className="img-block note-wgt__thumbnail" src={item.images.large} alt="" />
                        </div>
                      </Link>
                    </div>
                </div>
              </div>
            })}
        </div>
      </div>
      <div className="notes-pagination">
        {offset > 12 && <button className="btn-main btn-main_theme_white btn-main__submit" type="button" onClick={handlerPrev}>
          <svg className="btn-main__icon">
            <use href={sprite + "#arrow-prev"}></use>
          </svg>НАЗАД
        </button>}
        {offset < count && <button className="btn-main btn-main__submit" type="button" onClick={handlerNext}>ВПЕРЕД
          <svg className="btn-main__icon">
            <use href={sprite + "#arrow"}></use>
          </svg>
        </button>}
      </div>
    </section>
  );
}
export default BlogList;
