import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import './HomeCases.css';
import sprite from '../media/icons.svg';
SwiperCore.use([Navigation, Pagination]);
function HomeCases() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  function handlerSlideChange(){
    let homeCase = document.querySelector('.home-cases');
    if(homeCase.classList.contains('active')){
      homeCase.classList.remove('active');
      setTimeout(() => {
        homeCase.classList.add('active');
      }, 200);
    } else {
      setTimeout(() => {
        homeCase.classList.add('active');
      }, 200);
    }
  }

  useEffect(() => {
    fetch('https://admincode.code4u.com.ua/wp-json/wp/v2/cases')
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
      });
  }, [])
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  return (
    <section className="home-cases">
      <div className="home-cases__header container">
        <div className="home-cases__header-name">Проект</div>
        <div className="home-cases__header-line"></div>
        <div className="home-cases__header-name">Деталі</div>
      </div>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        pagination={{
          type: 'fraction',
          el: '.pagination'
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        onSlideChange={handlerSlideChange}
      >
      {Object.values(items).map((item, index) => {
        let obj = Object.assign({}, item.acf.gallery);
        let products = Object.values(obj).map((product, i) => {
          return          <div className="home-case-wgt__img-wrap" key={i}>
                            <img className="img-block" src={product} alt="" />
                          </div>
        });
        return <SwiperSlide className="home-case-wgt" key={index}>
          <div className="home-case-wgt__img" style={{ backgroundImage: `url(${item.images.large})` }}>
            <div className="overlay"></div>
            <div className="home-case-wgt__animate"></div>
          </div>
          <div className="container">
            <div className="home-case-wgt__row container__row">
              <div className="home-case-wgt__left">
                <p>{item.acf.description}</p>
                <Link className="home-case-wgt__headline-link" to={`/cases/${item.slug}`} params={{slug: item.slug}}>
                  <h2 className="home-case-wgt__headline">{item.title.rendered}</h2>
                </Link>
                {item.acf.link && <a className="home-case-wgt__link" href={item.acf.link} target="_blank">Переглянути сайт проєкту</a>}
              </div>
              <div className="home-case-wgt__right">
                <ul className="home-case-list">
                  <li className="home-case-list__item">{item.acf.city}</li>
                  <li className="home-case-list__item">{item.acf.date}</li>
                  <li className="home-case-list__item">{item.acf.description}</li>
                </ul>
                <Link className="btn-main" to={`/cases/${item.slug}`} params={{slug: item.slug}}>Детальніше
                  <svg className="btn-main__icon">
                    <use href={sprite + "#arrow"}></use>
                  </svg>
                </Link>
              </div>
              <div className="home-case-wgt__imgs">
                {products}
              </div>
            </div>
          </div>
        </SwiperSlide>
      })}
        <div className="home-case-wgt__nav">
          <div className="container">
            <button className="home-case-wgt__nav-btn" type="button" ref={navigationNextRef}>Вперед</button>
            <button className="home-case-wgt__nav-btn" type="button" ref={navigationPrevRef}>Назад</button>
            <div className="pagination"></div>
          </div>
        </div>
      </Swiper>
    </section>
  );
}
export default HomeCases;
