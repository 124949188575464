import React from "react";
import './PortfolioInfo.css';
function PortfolioInfoWgt(props) {
return (
<div className="portfolio-info-wgt">
  <div className="portfolio-info-wgt__inner">
    <div className="portfolio-info-wgt__num">{props.num}</div>
    <div className="portfolio-info-wgt__content">
      <div className="portfolio-info-wgt__headline">{props.name}</div>
      <div className="portfolio-info-wgt__description">{props.desc}</div>
    </div>
  </div>
</div>
);
}
export default PortfolioInfoWgt;
