import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './HomeBanner.css';
import sprite from '../media/icons.svg';
function HomeBanner(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [contacts, setContacts] = useState([]);

  function handlerMouseBanner(e){
    let eyes = document.querySelector('.home-banner__want a');
    let mouseX = (eyes.getBoundingClientRect().left);
    let mouseY = (eyes.getBoundingClientRect().top);
    let radianDegrees = Math.atan2(e.pageX - mouseX, e.pageY - mouseY);
    let rotationDegrees = (radianDegrees * (180/ Math.PI) * -1) + 180;
    eyes.style.transform = `rotate(${rotationDegrees}deg)`;
  }
  useEffect(() => {
    fetch("https://admincode.code4u.com.ua/wp-json/acf/v3/options/acf-options-theme")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
      fetch("https://admincode.code4u.com.ua/wp-json/acf/v3/options/acf-contacts")
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setContacts(result);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
  }, []);
  const contactsObj = Object.assign({}, contacts.acf);
  return (
    <section className="home-banner" onMouseMove={handlerMouseBanner}>
      <div className="home-banner__container container">
        <div className="container__row">
            {Object.values(items).map((item, index) => {
              return <div className="home-banner__content" key={index}>
                       <h1 className="home-banner__headline">{item.home_headline}</h1>
                            <p className="home-banner__description">{item.home_description}</p>
                            <Link className="home-banner__btn btn-main" to="/contacts">Зв'язатися
                              <svg className="btn-main__icon">
                                <use href={sprite + "#arrow"}></use>
                              </svg>
                            </Link>
                          </div>
            })}
          <div className="home-banner__want">
            <Link to="/contacts">
              <svg className="want-svg" xmlns="http://www.w3.org/2000/svg" href="http://www.w3.org/1999/xlink" width="300" height="300">
                <defs>
                  <path d="M25,100a100,100 0 1,0 200,0a100,100 0 1,0 -200,0" id="textcircle" />
                </defs>
                <text>
                  <textPath href="#textcircle">хочу такий сайт хочу такий сайт</textPath>
                </text>
              </svg>
            </Link>
          </div>
        </div>
        <ul className="banner-social">
          <li className="banner-social__item">
            <a className="banner-social__link" href={contactsObj.instagram} target="_blank">Instagram</a>
          </li>
          <li className="banner-social__item">
            <a className="banner-social__link" href={contactsObj.facebook} target="_blank">Facebook</a>
          </li>
          <li className="banner-social__item">
            <a className="banner-social__link" href={contactsObj.telegram} target="_blank">Telegram</a>
          </li>
        </ul>
        <div className="banner-footer">
          <button className="button-footer__scroll" type="button">Скрол</button>
        </div>
      </div>
      <div className="logotype-items">
        {props.logo}
      </div>
    </section>
  );
}
export default HomeBanner;
