import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import HomeBanner from './components/HomeBanner';
import HomeProducts from './components/HomeProducts';
import HomeCases from './components/HomeCases';
import StartProject from './components/StartProject';
import HomeNotes from './components/HomeNotes';
function Home(props) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  if(location.pathname === '/'){
    document.body.classList.add('main-page');
  }
  useEffect(() => {
    document.title = "CODE4U | Цифровізуємо все, що цифровізується!";
  }, []);
  function elementInViewport(el, num){
      var bounds = el.getBoundingClientRect();
      return (
          ((bounds.top - num) + (bounds.height) > 0) && // Елемент ниже верхней границы
          ((window.innerHeight - num) - (bounds.top) > 0) && // Выше нижней
          ((bounds.left  - num) + (bounds.width) > 0) && // Правее левой
          ((window.innerWidth  - num) - (bounds.left) > 0)// Левее правой
      );
  }
  function handlerScroll(){
    var el = document.querySelectorAll(".home-case-wgt");
      var inViewport = elementInViewport(el[0], 300);
      if(inViewport){
        el[0].classList.add('active');
      }
      var el2 = document.querySelector(".home-cases");
        var inViewport2 = elementInViewport(el2, 300);
        if(inViewport2){
          el2.classList.add('active');
        }
        var el3 = document.querySelector(".start-project");
          var inViewport3 = elementInViewport(el3, 50);
          if(inViewport3){
            el3.classList.add('active');
          }
  }
  return (
    <>
    <div onWheel={handlerScroll}>
      <HomeBanner logo={props.logoResult}/>
      <HomeProducts />
      <HomeCases />
      <StartProject />
      <HomeNotes />
    </div>
    </>
  );
}
export default Home;
