import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import sprite from '../media/icons.svg';
import './StartProject.css';
import SuccessForm from './SuccessForm';
function StartProject() {
  const [valueName, setValueName] = useState('');
  const [valuePhone, setValuePhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(null);
  const nameRef = useRef();
  const phoneRef = useRef();
  const location = useLocation();
  function handlerValueName(event){
    setValueName(event.target.value);
  }
  function handlerValuePhone(event){
    setValuePhone(event.target.value);
  }
  function handlerSubmit(event){
    event.preventDefault();
      setLoading(true);
      fetch("https://code4u.com.ua/form-submit-url.php",
        {   method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            mode : 'same-origin',
            credentials: 'same-origin' ,
            body: JSON.stringify({name: valueName, phone: valuePhone})
        })
        .then(function(response) {
          return response.text()
        }).then(function(text) {
          let result = JSON.parse(text);
          if(result == 'success'){
            setSuccess(true);
            setLoading(false);
          } else {
            setErrors(result);
            setLoading(false);
            setTimeout(() => {
              setErrors(null);
            }, 5000);
          }
        });
  }
  return (
    <section className="start-project">
      <div className="container">
        {success ? <SuccessForm /> : <div className="start-project__row container__row">
                  <h2 className="start-project__headline">{location.pathname === '/' ? 'Замовити послуги' : 'Хочу замовити проєкт!' }</h2>
                  <div className={loading ? 'form-wrapper form-wrapper__load' : 'form-wrapper'}>
                    {loading && <div class="load-3">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
              </div>}
                    <form className={loading ? 'form form__loading' : 'form'} onSubmit={handlerSubmit}>
                      <div className="input-container">
                        <input type="text" id="name" name="name" ref={nameRef} value={valueName} onChange={handlerValueName} />
                        <label htmlFor="name">{!valueName && 'Як до Вас звертатися?'}</label>
                        <div className="bar"></div>
                      </div>
                      <div className="input-container">
                        <input type="text" id="phone" name="phone" ref={phoneRef} value={valuePhone} onChange={handlerValuePhone} />
                        <label htmlFor="phone">{!valuePhone && 'Номер телефону'}</label>
                        <div className="bar"></div>
                      </div>
                      {errors && <div className="errors">
                        <svg className="errors__icon">
                          <use href={sprite + "#error-icon"}></use>
                        </svg>
                        <div className="errors__content">
                        {Object.values(errors).map((item, index) => {
                          return <p className="error" key={index}>{item}</p>;
                        })}
                        </div>
                      </div>}
                      <div className="form__btn">
                        <button className="btn-main btn-main_theme_white btn-main__submit" type="submit" name="submit">Відправити
                          <svg className="btn-main__icon">
                            <use href={sprite + "#arrow"}></use>
                          </svg>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>}
      </div>
    </section>
  );
}
export default StartProject;
