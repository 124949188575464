import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import sprite from '../media/icons.svg';
import Menu from './Menu';
import './Header.css';
function Header(props) {
  let [showMenu, setShowMenu] = useState(false);
  return (
    <>
      <header className="header">
        <div className="container">
          <div className="header__row container__row">
            <div className="header__logotype">
              <Link to="/">
                <img src={props.theme ? 'https://admincode.code4u.com.ua/wp-content/uploads/2021/11/light_logo.svg' : 'https://admincode.code4u.com.ua/wp-content/uploads/2021/11/logo.svg'} alt="" />
              </Link>
            </div>
            <div className="header__btn-menu">
              <button className="btn-menu" type="button" onClick={() => setShowMenu(!showMenu)}>
                <svg className="btn-menu__icon">
                  <use href={sprite + "#burger-icon"}></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </header>
      {showMenu && <Menu showFun={setShowMenu} show={showMenu} />}
    </>
  );
}
export default Header;
