import React, { useState, useEffect } from "react";
import './NoteContent.css';
function AboutContent(props) {
  return (
    <section className="note-content note-content__about">
      {Object.values(props.items).map((item, index) => {
        return <div className="container" key={index} dangerouslySetInnerHTML={{
          __html: item.content.rendered
        }}></div>
      })}
    </section>
  );
}
export default AboutContent;
