import React from "react";
import successIcon from '../media/success.png';
import './SuccessForm.css';
function SuccessForm() {
  return (
    <div className="success-form">
      <img className="success-form__icon" src={successIcon} alt="" />
      <div className="success-form__info">
        <h2 className="success-form__headline">Дякуємо за Ваше звернення!</h2>
        <p className="success-form__description">Наш менеджер зв'яжеться з вами найближчим часом</p>
      </div>
    </div>
  );
}
export default SuccessForm;
