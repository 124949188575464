import React from "react";
import './AboutWhatWeDo.css';
function AboutWhatWeDo(props) {
  return (
    <section className="about-whatwedo">
      <div className="container">
        <div className="container__row">
          <div className="about-section-name">
            <h2 className="about-section-headline">Що ми робимо</h2>
          </div>
          {Object.values(props.items).map((item, index) => {
            let obj = Object.assign({}, item.acf.what_we_do);
            let whatwedo = Object.values(obj).map((what, i) => {
              return       <div className="whatwedo-wgt" key={i}>
                            <div className="whatwedo-wgt__inner">
                              <h3 className="whatwedo-wgt__headline">{what.name}</h3>
                              <p className="whatwedo-wgt__description">{what.description}</p>
                            </div>
                          </div>
            });
            return <div className="about-section-content" key={index}>{whatwedo}</div>
          })}
        </div>
      </div>
    </section>
  );
}
export default AboutWhatWeDo;
