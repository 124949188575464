import React from "react";

const MyMarker = ({ text, tooltip }) => (
  <svg className="marker" title={tooltip} viewBox="0 0 218 218" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="109.396" cy="109.242" r="108.529" fill="white" fill-opacity="0.09"/>
  <circle cx="109.396" cy="109.242" r="72.5705" fill="white" fill-opacity="0.09"/>
  <circle cx="109.396" cy="109.242" r="47.0727" fill="white" fill-opacity="0.16"/>
  <g clip-path="url(#clip0_63_2255)">
  <path d="M109.069 69.3606C90.3002 69.3606 75.0312 84.6296 75.0312 103.398C75.0312 121.555 106.15 162.921 107.475 164.675C107.848 165.175 108.445 165.467 109.069 165.467C109.694 165.467 110.291 165.175 110.663 164.675C111.988 162.921 143.107 121.555 143.107 103.398C143.107 84.6296 127.838 69.3606 109.069 69.3606ZM109.069 121.418C99.134 121.418 91.049 113.333 91.049 103.398C91.049 93.4634 99.134 85.3784 109.069 85.3784C119.004 85.3784 127.089 93.4634 127.089 103.398C127.089 113.333 119.004 121.418 109.069 121.418Z" fill="white"/>
  </g>
  <defs>
  <clipPath id="clip0_63_2255">
  <rect width="96.1068" height="96.1068" fill="white" transform="translate(61.0156 69.3606)"/>
  </clipPath>
  </defs>
  </svg>
);

export default MyMarker;
