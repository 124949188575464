import React from "react";
import PortfolioInfoWgt from './PortfolioInfoWgt';
import './PortfolioInfo.css';
function PortfolioInfo(props) {
  return (
    Object.values(props.item).map((item, index) => {
    return <section className="portfolio-info" key={index}>
      <div className="container">
        <div className="portfolio-info__row container__row">
          <PortfolioInfoWgt num={'01'} name={'Коли'} desc={item.acf.date} />
          <PortfolioInfoWgt num={'02'} name={'Що'} desc={item.acf.description} />
          <PortfolioInfoWgt num={'03'} name={'Для кого'} desc={item.acf.developers} />
        </div>
      </div>
    </section>
    })
  );
}
export default PortfolioInfo;
