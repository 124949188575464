import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import sprite from './media/icons.svg';
import GoogleMapReact from "google-map-react";
import MyMarker from "./components/MyMarker";
import SuccessFormContacts from './components/SuccessFormContacts';
import './components/Contacts.css';
function Contacts() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = "CODE4U | Наші контакти";
  }, []);
  if(location.pathname !== '/'){
    document.body.classList.remove('main-page');
  }
  const [valueName, setValueName] = useState('');
  const [valuePhone, setValuePhone] = useState('');
  const [valueEmail, setValueEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [errors, setErrors] = useState(null);
  function handlerValueName(event){
    setValueName(event.target.value);
  }
  function handlerValueEmail(event){
    setValueEmail(event.target.value);
  }
  function handlerValuePhone(event){
    setValuePhone(event.target.value);
  }

  function handlerSubmitContact(event){
    event.preventDefault();
    fetch("https://code4u.com.ua/form-submit-contacts.php",
      {   method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          mode : 'same-origin',
          credentials: 'same-origin' ,
          body: JSON.stringify({name: valueName, phone: valuePhone, email: valueEmail})
      })
      .then(function(response) {
        return response.text()
      }).then(function(text) {
        let result = JSON.parse(text);
        if(result == 'success'){
          setSuccess(true);
          setLoading(false);
        } else {
          setErrors(result);
          setLoading(false);
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        }
      });
  }
  useEffect(() => {
      fetch("https://admincode.code4u.com.ua/wp-json/acf/v3/options/acf-contacts")
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setContacts(result);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
  }, []);
  const contactsObj = Object.assign({}, contacts.acf);
  const distanceToMouse = (pt, mp) => {
    if (pt && mp) {
      return Math.sqrt(
        (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
      );
    }
  };
  const points = [
    { id: 1, title: "CODE4U", lat: 49.587169194308785, lng: 34.55852633927799 },
  ];
  return (
    <>
      <section className="contacts">
        <div className="contacts__inner">
          <div className="contacts__form-bg"></div>
          <div className="contacts__form-map">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyCX4fxbyL5sKqFm2QBOwlDQ-x_-Cn2I3jU",
                language: "uk",
                region: "UK"
              }}
              defaultCenter={{ lat: 49.587169194308785, lng: 34.55852633927799 }}
              defaultZoom={15}
              distanceToMouse={distanceToMouse}
              options={{
     styles: [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#181818"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1b1b1b"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#2c2c2c"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8a8a8a"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#373737"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#4e4e4e"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#3d3d3d"
      }
    ]
  }
]
   }}
              >
              {points.map(({ lat, lng, id, title }) => {
                return (
                  <MyMarker key={id} lat={lat} lng={lng} text={id} tooltip={title} />
                );
              })}
              </GoogleMapReact>
          </div>
          <div className="contacts__form">
            <div className="container">
              {!success && <h1 className="contacts__headline">Зв'яжіться з нами</h1>}
              <div className="contacts__form-wrapper">
              {loading && <div class="load-3">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
              </div>}
              {success ? <SuccessFormContacts /> :
                <form className={loading ? 'form form__loading' : 'form'} onSubmit={handlerSubmitContact}>
                  <div className="input-container">
                    <input type="text" id="name" value={valueName} onChange={handlerValueName} />
                    <label htmlFor="name">{!valueName && 'Як до Вас звертатися?'}</label>
                    <div className="bar"></div>
                  </div>
                  <div className="input-container">
                    <input type="text" id="email" value={valueEmail} onChange={handlerValueEmail} />
                    <label htmlFor="email">{!valueEmail && 'Електронна пошта'}</label>
                    <div className="bar"></div>
                  </div>
                  <div className="input-container">
                    <input type="text" id="phone" value={valuePhone} onChange={handlerValuePhone} />
                    <label htmlFor="phone">{!valuePhone && 'Номер телефону'}</label>
                    <div className="bar"></div>
                  </div>
                  {errors && <div className="errors">
                    <svg className="errors__icon">
                      <use href={sprite + "#error-icon"}></use>
                    </svg>
                    <div className="errors__content">
                    {Object.values(errors).map((item, index) => {
                      return <p className="error" key={index}>{item}</p>;
                    })}
                    </div>
                  </div>}
                  <div className="form__btn">
                    <button className="btn-main btn-main_theme_white btn-main__submit" type="submit">Відправити
                      <svg className="btn-main__icon">
                        <use href={sprite + "#arrow"}></use>
                      </svg>
                    </button>
                  </div>
                </form>}
              </div>
            </div>
          </div>
        </div>
        <div className="contacts__info">
          <div className="contacts__info-bg">
            <div className="contacts__info-bg_left"></div>
            <div className="contacts__info-bg_right"></div>
          </div>
          <div className="container">
            <div className="contacts__info-content">
              <h2 className="contacts__headline">Наші контакти</h2>
              <div className="contacts__info-content-inner">
                <div className="contacts-list-wrapper">
                  <ul className="contacts-list">
                    {contactsObj.address && <li className="contacts-list__item">
                      <svg className="contacts-list__icon">
                        <use href={sprite + "#geo-icon"}></use>
                      </svg>
                      <span className="contacts-list__value">{contactsObj.address}</span>
                    </li>}
                    {contactsObj.phone && <li className="contacts-list__item">
                      <svg className="contacts-list__icon">
                        <use href={sprite + "#phone-icon"}></use>
                      </svg>
                      <a className="contacts-list__value" href={"tel:" + contactsObj.phone}>{contactsObj.phone}</a>
                    </li>}
                    {contactsObj.email && <li className="contacts-list__item">
                    <svg className="contacts-list__icon">
                      <use href={sprite + "#mail-icon"}></use>
                    </svg>
                      <a className="contacts-list__value" href={"mailto:" + contactsObj.email}>{contactsObj.email}</a>
                    </li>}
                  </ul>
                </div>
                <div className="social-list-wrapper">
                  <h3 className="social-list-wrapper__headline">Слідкуйте за нами!</h3>
                  <ul className="social-list">
                    <li className="social-list__item">
                      <a className="social-list__link" href={contactsObj.instagram} target="_blank">Instagram</a>
                    </li>
                    <li className="social-list__item">
                      <a className="social-list__link" href={contactsObj.facebook} target="_blank">Facebook</a>
                    </li>
                    <li className="social-list__item">
                      <a className="social-list__link" href={contactsObj.telegram} target="_blank">Telegram</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Contacts;
