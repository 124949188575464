import React from "react";
import {
  Link
} from "react-router-dom";
import bannerImg from '../media/about-banner.png';
import './AboutBanner.css';
function AboutBanner(props) {
  return (
    Object.values(props.items).map((item, index) => {
    return <section className="about-banner" key={index}>
      <div className="overlay"></div>
      <img className="img-block" src={bannerImg} alt="" />
      <div className="about-banner__content">
        <div className="container">
          <div className="about-banner__content-inner">
            <h1 className="about-banner__headline">{item.title.rendered}</h1>
            <p className="about-banner__description">{item.acf.description}</p>
          </div>
        </div>
      </div>
    </section>
    })
  );
}
export default AboutBanner;
