import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useLocation
} from "react-router-dom";
import NoteContent from './components/NoteContent';
function SingleNote() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  if(location.pathname !== '/'){
    document.body.classList.remove('main-page');
  }
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [title, setTitle] = useState(false);
  let { slug } = useParams();
  useEffect(() => {
    fetch(`https://admincode.code4u.com.ua/wp-json/wp/v2/news?slug=${slug}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
      });
  }, [])
  Object.values(items).map((item, index) => {
    if(!title){
      setTitle(item.title.rendered);
    }
  });
  useEffect(() => {
    if(title){
      document.title = 'CODE4U | ' + title;
    }
  }, [title]);
  return (
    <>
      <div className="container">
        <ul className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link className="breadcrumbs__link" to="/">Главная</Link>
          </li>
          <li className="breadcrumbs__item">
            <Link className="breadcrumbs__link" to="/blog">Блог</Link>
          </li>
          <li className="breadcrumbs__item">{title}</li>
        </ul>
      </div>
      <NoteContent item={items} />
    </>
  );
}
export default SingleNote;
