import React from "react";
import {
  Link
} from "react-router-dom";
import './PortfolioBanner.css';
import bannerImg from '../media/portfolio_img.png';
function PortfolioBanner(props) {
  return (
    Object.values(props.item).map((item, index) => {
    return <section className="portfolio-banner" key={index}>
    <div className="container portfolio-banner__breadcrumbs">
      <ul className="breadcrumbs">
        <li className="breadcrumbs__item">
          <Link className="breadcrumbs__link" to="/">Главная</Link>
        </li>
        <li className="breadcrumbs__item">
          <Link className="breadcrumbs__link" to="/cases">Проєкти</Link>
        </li>
        <li className="breadcrumbs__item">{item.title.rendered}</li>
      </ul>
    </div>
      <div className="overlay"></div>
      <img className="img-block portfolio-banner__img" src={item.images.full} alt="" />
      <div className="portfolio-banner__content">
        <div className="container">
          <p>{item.acf.description}</p>
          <h1 className="portfolio-banner__headline">{item.title.rendered}</h1>
          {item.acf.link && <a className="portfolio-banner__link" href={item.acf.link} target="_blank">Переглянути сайт проєкту</a>}
        </div>
      </div>
    </section>
  })
  );
}
export default PortfolioBanner;
