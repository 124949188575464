import React from "react";
import './SuccessFormContacts.css';
function SuccessFormContacts() {
  return (
    <div className="success-form-contacts">
      <div className="success-form-contacts__info">
        <h2 className="success-form-contacts__headline">Дякуємо за Ваше звернення!</h2>
        <p className="success-form-contacts__description">Наш менеджер зв'яжеться з вами найближчим часом</p>
      </div>
    </div>
  );
}
export default SuccessFormContacts;
