import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import BlogHeader from './components/BlogHeader';
import BlogList from './components/BlogList';
function Blog() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = "CODE4U | Блог";
  }, []);
  if(location.pathname !== '/'){
    document.body.classList.remove('main-page');
  }
  return (
    <>
      <BlogHeader />
      <BlogList />
    </>
  );
}
export default Blog;
