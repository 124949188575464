import React, {useEffect} from "react";
import {
  Link,
  useLocation
} from "react-router-dom";
import sprite from './media/icons.svg';
import './components/NotFound.css';
function NotFound() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = "CODE4U | Сторінку не знайдено";
  }, []);
  if(location.pathname !== '/'){
    document.body.classList.remove('main-page');
  }
  return (
    <>
      <section className="not-found">
        <div className="container">
          <div className="not-found__code">404</div>
          <div className="not-found__headline">Ой!</div>
          <div className="not-found__description">На жаль, ми не можемо знайти зазначену сторінку!</div>
          <div className="not-found__home-link">
            <Link className="btn-main" to="/">Повернутися на головну сторінку
              <svg className="btn-main__icon">
                <use href={sprite + "#arrow"}></use>
              </svg>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
export default NotFound;
