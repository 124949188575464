import React from "react";
import './NoteContent.css';
function PortfolioContent(props) {
  return (
    <section className="note-content note-content__case">
      {Object.values(props.item).map((item, index) => {
        return <div className="container" dangerouslySetInnerHTML={{
          __html: item.content.rendered
        }} key={index}></div>
      })}
    </section>
  );
}
export default PortfolioContent;
