import React, {useEffect} from "react";
import {
  Link
} from "react-router-dom";
import { useLocation } from "react-router-dom";
import PortfolioHeader from './components/PortfolioHeader';
import PortfolioList from './components/PortfolioList';
function Portfolio() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    document.title = "CODE4U | Наше портфоліо";
  }, []);
  if(location.pathname !== '/'){
    document.body.classList.remove('main-page');
  }
  return (
    <>
    <div className="container">
      <ul className="breadcrumbs">
        <li className="breadcrumbs__item">
          <Link className="breadcrumbs__link" to="/">Главная</Link>
        </li>
        <li className="breadcrumbs__item">Проєкти</li>
      </ul>
    </div>
      <PortfolioList />
    </>
  );
}
export default Portfolio;
