import React, { useState } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import './AboutAchievements.css';
function AboutAchievements(props) {
  const [visible, setVisible] = useState(false);
  const [load, setLoad] = useState(false);
  setTimeout(() => {
    setLoad(true);
  }, 5000);
  return (
    <section className="about-achievements">
      <VisibilitySensor partialVisibility onChange={(isVisible) => {
        if (isVisible) { setVisible(true); }
    }}>
      <div className="container">
        <div className="container__row">
          <div className="about-section-name">
            <h2 className="about-section-headline">Наші досягнення за минулий рік</h2>
          </div>
          {Object.values(props.items).map((item, index) => {
            let obj = Object.assign({}, item.acf.achievements);
            let achievements = Object.values(obj).map((achievement, i) => {
              return <div className="achievements-wgt" key={i}>
                <img className="img-block achievements-wgt__icon" src={props.icons ? achievement.icon_light : achievement.icon} alt="" />
                <div className="achievements-wgt__content">
                  <CountUp className="achievements-wgt__value" start={0} end={achievement.value} duration={3} />
                  <div className="achievements-wgt__headline">{achievement.headline}</div>
                </div>
              </div>
              });
             return <div className="about-section-content" key={index}>{achievements}</div>
        })}
        </div>
      </div>
      </VisibilitySensor>
    </section>
  );
}
export default AboutAchievements;
