import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import './PortfolioList.css';
import sprite from '../media/icons.svg';
function PortfolioList() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [items2, setItems2] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [offset, setOffset] = useState(10);
  const [count, setCount] = useState([]);
  useEffect(() => {
    fetch('https://admincode.code4u.com.ua/wp-json/wp/v2/cases')
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
      });
      fetch(`https://admincode.code4u.com.ua/wp-json/wp/v2/all-terms?term=projects-type`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setItems2(data);
        });
        fetch(`https://admincode.code4u.com.ua/wp-json/wp/v2/cases?per_page=100`)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            setCount(data.length);
          });
  }, [])
  function handlerCategory(id){
    setActiveItem(id);
    fetch(`https://admincode.code4u.com.ua/wp-json/wp/v2/cases?projects-type=${id}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
      });
  }
  function handlerAllItems(){
    setActiveItem(null);
    fetch(`https://admincode.code4u.com.ua/wp-json/wp/v2/cases`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
      });
  }
  function handlerPrev(){
      fetch(`https://admincode.code4u.com.ua/wp-json/wp/v2/cases?per_page=10&offset=${offset - 20}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setItems(data);
        });
        setOffset(offset - 10);
  }
  function handlerNext(){
    fetch(`https://admincode.code4u.com.ua/wp-json/wp/v2/cases?per_page=10&offset=${offset}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
        setOffset(offset + 10);
      });
  }
  return (
    <>
    <section className="blog-header">
      <div className="container">
        <h2 className="note-headline">Проекти</h2>
        <div className="note-category">Категорії</div>
        <ul className="category-nav">
        <li className="category-nav__item">
                <a className={activeItem ? 'category-nav__link' : 'category-nav__link active'} href="#" onClick={handlerAllItems}>Всі категорії</a>
              </li>
        {Object.values(items2).map((item, index) => {
          return <li className="category-nav__item" key={index}>
                  <a className={activeItem == item.term_taxonomy_id ? 'category-nav__link active' : 'category-nav__link'} href="#" onClick={() => handlerCategory(item.term_taxonomy_id)}>{item.name}</a>
                </li>
          })}
        </ul>
      </div>
    </section>
    <section className="portfolio-list">
      {Object.values(items).map((item, index) => {
        return <div className="portfolio-wgt" key={index}>
          <div className="portfolio-wgt__img" style={{ backgroundImage: `url(${item.images.large})` }}>
            <div className="overlay"></div>
          </div>
          <div className="container">
            <div className="portfolio-wgt__row container__row">
              <div className="portfolio-wgt__left">
                <p>{item.acf.description}</p>
                <Link className="portfolio-wgt__headline-headline" to={`/cases/${item.slug}`} params={{slug: item.slug}}>
                  <h2 className="portfolio-wgt__headline">{item.title.rendered}</h2>
                </Link>
              </div>
              <div className="portfolio-wgt__right">
                <ul className="home-case-list">
                  <li className="home-case-list__item">{item.acf.city}</li>
                  <li className="home-case-list__item">{item.acf.date}</li>
                  <li className="home-case-list__item">{item.acf.description}</li>
                </ul>
                <Link className="btn-main" to={`/cases/${item.slug}`} params={{slug: item.slug}}>Читати більше
                  <svg className="btn-main__icon">
                    <use href={sprite + "#arrow"}></use>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      })}
      <div className="notes-pagination">
        {offset > 10 && <button className="btn-main btn-main_theme_white btn-main__submit" type="button" onClick={handlerPrev}>
          <svg className="btn-main__icon">
            <use href={sprite + "#arrow-prev"}></use>
          </svg>НАЗАД
        </button>}
        {offset < count && <button className="btn-main btn-main__submit" type="button" onClick={handlerNext}>ВПЕРЕД
          <svg className="btn-main__icon">
            <use href={sprite + "#arrow"}></use>
          </svg>
        </button>}
      </div>
    </section>
    </>
  );
}
export default PortfolioList;
